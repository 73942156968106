import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

import Layout from '../../components/layout'
import Product from '../../components/Product'
import styles from './styles.module.scss'

function _slugify(str) {
  return slugify((str || '').toString().split(' ').sort().join(' '), { lower: true }).replace(/[^a-zA-Z0-9 ]/g, '')
}

export default ({ pageContext }) => {
  const [query, setQuery] = useState('')
  const [lines, setLines] = useState({})
  const [products, setProducts] = useState({})
  const [isAlternative, setIsAlternative] = useState(false)
  const [isAll, setIsAll] = useState(!!pageContext.isAll)

  useEffect(() => {
    const params = {}
    const parts = window.location.search.replace(/^\?/, '').split('&')

    for (const part of parts) {
      const [key, value] = (part || '').toString().split('=')
      params[key] = decodeURIComponent(value || '')
    }

    setQuery(params.q || '')
  }, [])


  useEffect(() => {
    const value = _slugify(query)
    const URIComponent = encodeURIComponent(query || '')
    window.history.replaceState(null, null, URIComponent ? `/medicamentos?q=${URIComponent}` : window.location.pathname)
    setIsAlternative(false)
    const products = {}

    if (query) {
      setIsAll(true)
      const lines = {}

      for (const lineID of pageContext.lines._ids) {
        const slug = _slugify(lineID)
        if (slug.includes(value) || value.includes(slug)) {
          lines[lineID] = lines[lineID] || {}
        }
      }

      if (Object.keys(lines).length === 0) {
        for (const id of pageContext.items._ids) {
          const item = pageContext.items[id]
          const drug = _slugify(item.drug)
          const brand = _slugify(item.brand)

          if (drug.includes(value) || value.includes(drug)) {
            products[item.line] = products[item.line] || {}
            products[item.line][item.id] = item
          }

          if (brand.includes(value) || value.includes(brand)) {
            products[item.line] = products[item.line] || {}
            products[item.line][item.id] = item
          }
        }
      }

      if (Object.keys(lines).length === 0) {
        for (const id of pageContext.brands._ids) {
          const item = pageContext.brands[id]
          if (!item.alternative) continue
          const slug = _slugify(item.label)
          if (value === slug) {
            setIsAlternative(true)
            for (const id of item.items)  {
              const item = pageContext.items[id]
              if (!item) continue
              products[item.line] = products[item.line] || {}
              products[item.line][item.id] = item
            }
          }
        }
      }

      products._lines = Object.keys(products)

      for (const line of products._lines) {
        products[line]._ids = Object.keys(products[line])
      }

      setLines(lines)

    } else if (!isAll && !query && Array.isArray(pageContext.products) && pageContext.products.length > 0) {

    } else {
      const payload = {
        ...pageContext.lines,
        _ids: undefined
      }
      delete payload._ids
      setLines(payload)
    }

    if (!products._lines || products._lines.length === 0) {
      setProducts({})
    } else {
      setProducts(products)
    }
  }, [query, isAll, pageContext.products, pageContext.brands, pageContext.items, pageContext.lines])

  let lineIDS = Object.keys(lines)
  if (lineIDS.length === 0 && pageContext.lines) {
    lineIDS = pageContext.lines._ids
  }

  return (
    <Layout title="Vademecum">
      <div className="search flex flex-col items-center content-center justify-center p-12">
        <h1 className="text-4xl mb-8">Vademecum</h1>
        <div className="input">
          <input
            value={query}
            onChange={e => setQuery(e.target.value)}
            type="search"
            placeholder="Buscar por principio activo o marca"
            className="shadow-lg w-full p-4 text-lg outline-none focus:shadow-outline focus:bg-blue-100"
          />
        </div>
      </div>

      {isAll && <section className={`${styles.container} py-16`}>
        {Object.keys(lines).length === 0 && query && !products._lines && <div className={`${styles.alert} border-b-2 pb-16 text-lg border-gray-300`}>
          <div>
            <FontAwesomeIcon icon={faExclamationCircle} /> <span className="text-gray-700">No encontramos resultados para la búsqueda <strong className="font-semibold text-black">{query}</strong>.</span> <span>Buscá dentro de las líneas disponibles:</span>
          </div>
        </div>}

        {isAlternative && products._lines && <div className={`${styles.alert} border-b-2 pb-16 text-lg border-gray-300 text-gray-700`}>
          <div className="text-center">
            <FontAwesomeIcon icon={faExclamationCircle} /> Tenemos los siguientes resultados alternativos a <strong className="font-semibold">{query}</strong>:
          </div>
        </div>}

        {!products._lines && <React.Fragment>
          <h2 className="text-xl text-center font-semibold mb-10 mt-5">Líneas disponibles</h2>
          <nav className="md:flex md:items-center md:flex-wrap md:justify-between">
            {lineIDS.map(lineID => (
              <div className="lg:w-1/3 md:w-1/2" key={lineID}>
                <Link
                  to={`/lineas/${lineID}${query && _slugify(lineID) !== _slugify(query) && products._lines ? '?q=' + encodeURIComponent(query) : ''}`}

                  className="rounded-lg p-4 text-xs capitalize shadow-lg hover:border-blue-500 flex items-center justify-between md:m-2 m-5"
                >
                  <span>{pageContext.lines[lineID].label}</span>
                  <span><FontAwesomeIcon icon={faChevronRight} /></span>
                </Link>
              </div>
            ))}
          </nav>
        </React.Fragment>}

        {products._lines && <React.Fragment>
          {products._lines.map((lineID) => {
            const line = pageContext.lines[lineID]
            return (
              <React.Fragment key={lineID}>
                <h3 className="text-2xl capitalize my-8">Línea {pageContext.lines[lineID].label}</h3>
                {products[lineID]._ids.map((productID) => {
                  const item = pageContext.items[productID]
                  const brand = pageContext.brands[item.brand]
                  const drug = pageContext.drugs[item.drug]

                  return (
                    <Product
                      item={item}
                      brand={brand}
                      drug={drug}
                      line={line}
                      key={productID}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </React.Fragment>}
      </section>}

      {!isAll && pageContext.isDrug && Array.isArray(pageContext.products) && pageContext.id && pageContext.products.length > 0 && <section className={`${styles.container} py-16`}>
        <div className="flex items-center my-8 border-b-2 border-indigo-600 pb-4 mb-4">
          {pageContext.line && <div className="mr-3 md:ml-0 ml-2">
            <Link to={`/lineas/${pageContext.line}`}><FontAwesomeIcon icon={faChevronLeft} /></Link>
          </div>}
          {!pageContext.line && <div className="mr-3">
            <Link to={`/medicamentos`}><FontAwesomeIcon icon={faChevronLeft} /></Link>
          </div>}
          <div>
            <h3 className="text-2xl capitalize">{pageContext.drugs[pageContext.id].label}</h3>
          </div>
        </div>
        <div className="md:p-0 p-3">
          {pageContext.products.map((productID) => {
            const item = pageContext.items[productID]
            if (!item) return null
            const brand = pageContext.brands[item.brand]
            const drug = pageContext.drugs[item.drug]
            const line = pageContext.lines[item.line]

            return (
              <Product
                item={item}
                brand={brand}
                drug={drug}
                line={line}
                key={productID}
              />
            )
          })}
        </div>
      </section>}

      {!isAll && pageContext.isLine && Array.isArray(pageContext.products) && pageContext.id && pageContext.products.length > 0 && <section className={`${styles.container} py-16`}>
        <div className="flex items-center my-8 border-b-2 border-indigo-600 pb-4 mb-4">
          <div className="mr-3 md:ml-0 ml-2">
            <Link to="/medicamentos"><FontAwesomeIcon icon={faChevronLeft} /></Link>
          </div>
          <div>
            <h3 className="text-2xl capitalize">{pageContext.lines[pageContext.id].label}</h3>
          </div>
        </div>
        <div className="md:flex md:flex-wrap md:space-between">
          {Object.keys(pageContext.products.reduce((hash, item) =>
            ({ ...hash, [item.drug]: true }), {})
          ).map(drugID => (
            <div key={drugID} className="md:flex-grow md:w-1/3 w-full py-1 px-4">
              <Link title={pageContext.drugs[drugID].label} className="flex justify-between items-center py-3 px-1 border-solid border-b border-gray-300" to={`/principio-activo/${drugID}`}>
                <span className="truncate text-gray-600 text-sm capitalize">{pageContext.drugs[drugID].label}</span>
                <span className="text-xs text-gray-400"><FontAwesomeIcon icon={faChevronRight} /></span>
              </Link>
            </div>
          ))}
        </div>
      </section>}
    </Layout>
  )
}
