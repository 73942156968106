import React from 'react'

import styles from './styles.module.scss'

export default function Product({ item, brand, drug, line }) {
  return (
    <div className={`${styles.container} text-xs rounded-lg mb-8`}>
      <div className={`${styles.top} p-4`}>
        <div className="flex justify-between">
          <div className="capitalize">
            <div>
              {brand.label} - {drug.label}
            </div>
            <div>
              {item.presentation}
            </div>
          </div>
          <div className="capitalize text-right">
            <div>{item.provider}</div>
            <div>{line.label}</div>
          </div>
        </div>

      </div>
      <div className={`${styles.bottom} flex items-center justify-between py-3 px-4`}>
        <div><strong className="font-semibold">Precio Wiri: ${Math.ceil(+item.price.wiri).toLocaleString('es-AR')}</strong> ({parseInt(item.price.discount * 100)}% descuento)</div>
        <div><strong className="font-semibold">Precio Público: ${Math.ceil(+item.price.list).toLocaleString('es-AR')}</strong></div>
      </div>
    </div>
  )
}
